<template>
  <!-- ACTION HISTORY MODAL SIDEBAR -->
  <div
    @click="$emit('overlayClicked')"
    class="modal-background-overlay2 h-16"
    :class="{ hidden: !isVisible }"
  ></div>
  <div
    class="modal-background-overlay"
    @click="$emit('overlayClicked')"
    :class="{ hidden: !isVisible }"
  ></div>
  <div
    class="modal-background-overlay3"
    @click="$emit('overlayClicked')"
    :class="{
      'modal-background-overlay--hidden': !isVisible,
      'modal-background-overlay--black': isVisible,
    }"
  ></div>
  <div
    style="z-index: 998"
    :class="{ 'modal-visible': isVisible }"
    class="
      modal
      bottom-0
      pb-12
      pt-28
      px-8
      overflow-y-auto
      bg-white
      w-1/3
      text-black
      flex flex-col
      z-50
      h-full
      fixed
    "
  >
    <div class="text-left z-50">
      <h1 class="text-xl font-bold">
        Edit Manual Folder - {{ selectedBrand ? selectedBrand.name : "" }}/{{
          folder.name
        }}
      </h1>

      <div class="leading-loose">
        <form @submit.prevent class="mt-4 bg-white">
          <div class="inline-block mt-2 w-full pr-1">
            <div
              v-if="selectedBrand"
              class="w-full px-2 py-2 text-sm text-gray-700 bg-gray-200 rounded"
            >
              {{ selectedBrand.name }}
            </div>
          </div>

          <div class="inline-block mt-2 w-full pr-1">
            <input
              class="w-full px-2 py-2 text-sm text-gray-700 bg-gray-200 rounded"
              name="actiontitle"
              type="text"
              placeholder="Folder Name"
              v-model="folder.name"
            />
          </div>

          <div class="mt-6">
            <button
              class="
                px-4
                mr-2
                py-2
                text-black
                font-light
                text-sm
                tracking-wider
                bg-gray-100
                hover:bg-gray-200
                rounded
              "
              @click.prevent="$emit('overlayClicked')"
            >
              Cancel
            </button>
            <button
              class="
                px-4
                py-2
                text-sm text-white
                font-light
                tracking-wider
                bg-gray-900
                hover:bg-gray-700
                rounded
              "
              :disabled="!canSave"
              :class="{
                'cursor-not-allowed': !canSave,
              }"
              type="submit"
              @click.prevent="saveFolder"
            >
              Update
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- INQUIRY MODAL SIDEBAR -->
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "EditManualFolderModal",
  props: ["isVisible", "manualFolder"],
  emits: ["overlayClicked"],
  data() {
    return {
      folder: {
        name: "",
      },
    };
  },
  methods: {
    ...mapActions(["editManualFolder"]),
    async saveFolder() {
      try {
        await this.editManualFolder({
          folder: this.folder,
          brandid: this.$route.params.brandid,
        });
        this.$emit("overlayClicked");
      } catch (e) {
        this.$errorHandler(e);
      }
    },
  },
  computed: {
    ...mapGetters(["documentBrands"]),
    selectedBrand() {
      if (!this.documentBrands || !this.documentBrands.length) return null;
      const selectedIndex = this.documentBrands.findIndex(
        (brand) => brand._id === this.$route.params.brandid
      );
      if (selectedIndex === -1) return null;
      return this.documentBrands[selectedIndex];
    },
    canSave() {
      if (this.folder.name) return true;
      else return false;
    },
  },
  mounted() {
    this.folder = JSON.parse(JSON.stringify(this.manualFolder));
  },
};
</script>

<style scoped>
.modal-background-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 997;
}

.modal-background-overlay2 {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1001;
}

.modal-background-overlay3 {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 49;
  transition: background 0.2s 0.3s, visibility 1s;
}

.modal-background-overlay--hidden {
  visibility: hidden;
}

.modal-background-overlay--black {
  background: rgba(0, 0, 0, 0.8);
  visibility: visible;
}

.modal {
  transition: transform 0.5s;
  right: 0;
  transform: translatex(100%);
}

.modal-visible {
  transform: translatex(0%);
}
</style>