<template>
  <div>
    <div
      :style="{ marginLeft: topBarLeftMargin, width: contentAreaWidth }"
      class="
        content-area
        left-0
        mt-14
        w-full
        bg-gradient-to-r
        from-gray-300
        bg-gray-100
        p-1
        flex
        justify-between
        z-20
        fixed
      "
    >
      <ul class="flex items-center ml-1">
        <div>
          <svg
            v-if="!folderStack.length"
            @click="$router.go(-1)"
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 24 24"
            fill="none"
            class="mr-2 hover:bg-gray-100 rounded-full px-1 cursor-pointer"
            stroke="#000000"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path d="M15 18l-6-6 6-6" />
          </svg>
          <svg
            v-else
            @click="
              folderStack.pop(),
                getManualFolders({
                  brand: this.selectedBrand._id,
                  folder: folderStack[folderStack.length - 1]
                    ? folderStack[folderStack.length - 1]._id
                    : null,
                })
            "
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 24 24"
            fill="none"
            class="mr-2 hover:bg-gray-100 rounded-full px-1 cursor-pointer"
            stroke="#000000"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path d="M15 18l-6-6 6-6" />
          </svg>
        </div>
        <li class="font-bold mr-3" v-if="selectedBrand">
          {{ selectedBrand.name }}
          <span v-for="folder in folderStack" :key="folder._id"
            >&rarr; {{ folder.name }}</span
          >
        </li>
        <div class="relative text-gray-600">
          <input
            v-model="searchString"
            type="search"
            name="serch"
            placeholder="Search"
            class="
              bg-gray-100
              h-7
              w-96
              px-4
              pr-10
              rounded-lg
              text-sm
              focus:outline-none
            "
          />
          <button type="submit" class="absolute right-0 top-0 mt-1.5 mr-4">
            <svg
              class="h-4 w-4 fill-current"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              version="1.1"
              id="Capa_1"
              x="0px"
              y="0px"
              viewBox="0 0 56.966 56.966"
              style="enable-background: new 0 0 56.966 56.966; color: #cfb47e"
              xml:space="preserve"
              width="512px"
              height="512px"
            >
              <path
                d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z"
              />
            </svg>
          </button>
        </div>
      </ul>
      <ul class="flex items-center">
        <li
          style="color: #cfb47e"
          class="
            cursor-pointer
            bg-white
            border
            items-center
            text-sm
            py-1
            mr-2
            rounded-lg
            px-2
            hover:bg-gray-900
            flex
          "
          @click="newManualFolderModalVisible = true"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-3 w-3 mr-1"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#cfb47e"
            stroke-width="2.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <line x1="12" y1="5" x2="12" y2="19"></line>
            <line x1="5" y1="12" x2="19" y2="12"></line>
          </svg>
          New Folder
        </li>
        <li
          style="color: #cfb47e"
          class="
            cursor-pointer
            bg-white
            border
            items-center
            text-sm
            py-1
            mr-2
            rounded-lg
            px-2
            hover:bg-gray-900
            flex
          "
          @click="newManualModalVisible = true"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-3 w-3 mr-1"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#cfb47e"
            stroke-width="2.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <line x1="12" y1="5" x2="12" y2="19"></line>
            <line x1="5" y1="12" x2="19" y2="12"></line>
          </svg>
          New Manual
        </li>
      </ul>
    </div>
  </div>

  <NewManualModal
    @overlayClicked="newManualModalVisible = false"
    :isVisible="newManualModalVisible"
    :currentFolder="folderStack[folderStack.length - 1]"
  />

  <NewManualFolderModal
    :isVisible="newManualFolderModalVisible"
    @overlayClicked="newManualFolderModalVisible = false"
    :currentFolder="folderStack[folderStack.length - 1]"
  ></NewManualFolderModal>

  <div class="inq-table">
    <div>
      <div class="flex flex-col">
        <!-- There was flex grow here -->
        <div class="grid grid-cols-8 p-5 gap-5">
          <!-- <SearchLoadingOverlay v-if="loading"></SearchLoadingOverlay> -->
          <div
            v-for="folder in filteredFolders"
            :key="folder._id"
            class="
              cursor-pointer
              z-10
              relative
              bg-gray-200
              text-center
              rounded-2xl
              transform
              hover:shadow-xl hover:-translate-y-1 hover:scale-108
              duration-300
              ease-in-out
            "
          >
            <button
              @click.prevent="
                (dropDownOverlayShown = true), (folder.showDropDown = true)
              "
              class="
                absolute
                bg-black
                text-white
                rounded-full
                px-2
                right-2
                flex
                items-center
                justify-center
                opacity-30
                top-2
              "
            >
              <svg
                class="h-5 w-5 fill-current text-grey-dark"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path
                  d="M4 12a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm6 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm6 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
                />
              </svg>
            </button>
            <div
              v-if="folder.showDropDown"
              class="dropdown absolute top-0 right-0 z-50"
            >
              <ul class="w-full bg-white shadow rounded text-xl">
                <li
                  @click.prevent="
                    (folder.showDropDown = false),
                      (folder.editModalShown = true)
                  "
                  class="py-2 px-4 text-sm hover:bg-gray-100"
                >
                  Edit
                </li>
                <li
                  @click.prevent="
                    (folder.showDropDown = false),
                      (folder.deleteConfirmVisible = true)
                  "
                  class="py-2 px-4 text-sm hover:bg-gray-100"
                >
                  Delete
                </li>
              </ul>
            </div>
            <p
              class="pt-6"
              @click="
                folderStack.push(folder),
                  getManualFolders({
                    brand: this.selectedBrand._id,
                    folder: folderStack[folderStack.length - 1]._id,
                  })
              "
            >
              <svg
                class="mx-auto mb-4"
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#000000"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path
                  d="M11 21H4a2 2 0 0 1-2-2V5c0-1.1.9-2 2-2h5l2 3h9a2 2 0 0 1 2 2v2M19 15v6M16 18h6"
                />
              </svg>
            </p>
            <h1
              @click="
                folderStack.push(folder),
                  getManualFolders({
                    brand: this.selectedBrand._id,
                    folder: folderStack[folderStack.length - 1]._id,
                  })
              "
              class="uppercase pb-6 text-black font-bold text-xs py-1"
            >
              {{ folder.name }}
            </h1>
          </div>
          <a
            v-for="manual in filteredManuals"
            :key="manual._id"
            target="_blank"
            :href="`${api_host}/uploads/${manual.file}`"
            class="
              cursor-pointer
              z-10
              relative
              bg-gray-200
              text-center
              rounded-2xl
              p-6
              transform
              hover:shadow-xl hover:-translate-y-1 hover:scale-108
              duration-300
              ease-in-out
            "
          >
            <button
              @click.prevent="
                (dropDownOverlayShown = true), (manual.showDropDown = true)
              "
              class="
                absolute
                bg-black
                text-white
                rounded-full
                px-2
                right-2
                flex
                items-center
                justify-center
                opacity-30
                top-2
              "
            >
              <svg
                class="h-5 w-5 fill-current text-grey-dark"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path
                  d="M4 12a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm6 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm6 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
                />
              </svg>
            </button>
            <div
              v-if="manual.showDropDown"
              class="dropdown absolute top-0 right-0 z-50"
            >
              <ul class="w-full bg-white shadow rounded text-xl">
                <li
                  @click.prevent="
                    (manual.showDropDown = false),
                      (manual.deleteConfirmVisible = true)
                  "
                  class="py-2 px-4 text-sm hover:bg-gray-100"
                >
                  Delete
                </li>
              </ul>
            </div>
            <p>
              <svg
                class="mx-auto mb-4"
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#000000"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path
                  d="M14 2H6a2 2 0 0 0-2 2v16c0 1.1.9 2 2 2h12a2 2 0 0 0 2-2V8l-6-6z"
                />
                <path d="M14 3v5h5M16 13H8M16 17H8M10 9H8" />
              </svg>
            </p>
            <h1 class="uppercase text-black font-bold text-xs py-1">
              {{ manual.name }}
            </h1>
          </a>
          <div
            @click="hideDropDowns"
            v-if="dropDownOverlayShown"
            style="z-index: 0"
            class="fixed right-0 top-0 left-0 bottom-0"
          ></div>
        </div>
        <EditManualFolderModal
          v-for="folder in filteredFolders"
          :key="folder._id"
          :manualFolder="folder"
          :isVisible="folder.editModalShown"
          @overlayClicked="folder.editModalShown = false"
        ></EditManualFolderModal>
        <!-- DELETE CONFIRM MODAL -->
        <div
          v-for="folder in filteredFolders"
          :key="folder._id"
          v-show="folder.deleteConfirmVisible"
          @click="folder.deleteConfirmVisible = false"
          class="confirm-overlay"
        ></div>
        <div
          v-for="folder in filteredFolders"
          :key="folder._id"
          v-show="folder.deleteConfirmVisible"
          class="delete-confirm text-xl shadow rounded p-10"
        >
          <div>
            Delete
            <span class="font-bold">{{ folder.name }}</span> from manual
            folders?
            <br />
            <span class="inline-block my-4 text-red-700"
              >This action cannot be undone.</span
            >
          </div>
          <div class="flex justify-end mt-6">
            <button
              @click="folder.deleteConfirmVisible = false"
              class="py-2 px-4 bg-green-600 text-white rounded mr-2"
            >
              No
            </button>
            <button
              @click="
                (folder.deleteConfirmVisible = false),
                  deleteManualFolder(folder._id)
              "
              class="py-2 px-4 bg-red-600 text-white rounded"
            >
              Yes
            </button>
          </div>
        </div>
        <!-- / DELETE CONFIRM MODAL -->

        <!-- DELETE CONFIRM MODAL -->
        <div
          v-for="manual in filteredManuals"
          :key="manual._id"
          v-show="manual.deleteConfirmVisible"
          @click="manual.deleteConfirmVisible = false"
          class="confirm-overlay"
        ></div>
        <div
          v-for="manual in filteredManuals"
          :key="manual._id"
          v-show="manual.deleteConfirmVisible"
          class="delete-confirm text-xl shadow rounded p-10"
        >
          <div>
            Delete
            <span class="font-bold">{{ manual.name }}</span> from manuals?
            <br />
            <span class="inline-block my-4 text-red-700"
              >This action cannot be undone.</span
            >
          </div>
          <div class="flex justify-end mt-6">
            <button
              @click="manual.deleteConfirmVisible = false"
              class="py-2 px-4 bg-green-600 text-white rounded mr-2"
            >
              No
            </button>
            <button
              @click="
                (manual.deleteConfirmVisible = false), deleteManual(manual._id)
              "
              class="py-2 px-4 bg-red-600 text-white rounded"
            >
              Yes
            </button>
          </div>
        </div>
        <!-- / DELETE CONFIRM MODAL -->
      </div>
    </div>
  </div>
</template>

<script>
import NewManualModal from "@/components/modules/manuals/NewManualModal";
import NewManualFolderModal from "@/components/modules/manuals/NewManualFolderModal";
import EditManualFolderModal from "@/components/modules/manuals/EditManualFolderModal";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "PartsManuals",
  data() {
    return {
      searchString: "",
      newManualModalVisible: false,
      newManualFolderModalVisible: false,
      dropDownOverlayShown: false,
      folderStack: [],
      api_host: process.env.VUE_APP_API_HOST,
    };
  },
  methods: {
    ...mapActions([
      "getManuals",
      "deleteManual",
      "getDocumentBrands",
      "getManualFolders",
    ]),
    hideDropDowns() {
      this.filteredFolders.forEach((folder) => (folder.showDropDown = false));
      this.filteredManuals.forEach((manual) => (manual.showDropDown = false));
    },
  },
  computed: {
    ...mapGetters(["manuals", "documentBrands", "manualFolders"]),
    selectedBrand() {
      if (!this.documentBrands || !this.documentBrands.length) return null;
      const selectedIndex = this.documentBrands.findIndex(
        (brand) => brand._id === this.$route.params.brandid
      );
      if (selectedIndex === -1) return null;
      return this.documentBrands[selectedIndex];
    },
    selectedFolder() {
      if (!this.manualFolders || !this.manualFolders.length) return null;
      const selectedIndex = this.manualFolders.findIndex(
        (folder) => folder._id === this.$route.params.folderid
      );
      if (selectedIndex === -1) return null;
      return this.manualFolders[selectedIndex];
    },
    filteredManuals() {
      this.searchString;
      const regexp = new RegExp(this.searchString, "ig");
      return this.manuals.filter((manual) => {
        manual.showDropDown = false;
        manual.deleteConfirmVisible = false;
        return regexp.test(manual.name) || regexp.test(manual.keywords);
      });
    },

    filteredFolders() {
      this.searchString;
      const regexp = new RegExp(this.searchString, "ig");

      return this.manualFolders.filter((folder) => {
        folder.showDropDown = false;
        folder.editModalShown = false;
        folder.deleteConfirmVisible = false;
        return regexp.test(folder.name);
      });
    },
    sidebarCollapsed: {
      get() {
        return this.$store.state.ui.sidebarCollapsed;
      },
      set(newVal) {
        this.$store.commit("SET_SIDEBAR_COLLAPSE_STATE", newVal);
      },
    },
    topBarLeftMargin() {
      this.sidebarCollapsed;
      if (this.sidebarCollapsed) return "4rem";
      else return "16.5%";
    },
    contentAreaWidth() {
      this.sidebarCollapsed;
      if (this.sidebarCollapsed) return "calc(100% - 4rem)";
      else return "83.5%";
    },
  },
  mounted() {
    this.getDocumentBrands();

    let getFolderOptions = {
      brand: this.$route.params.brandid,
    };

    this.getManualFolders(getFolderOptions);
  },
  components: {
    NewManualModal,
    NewManualFolderModal,
    EditManualFolderModal,
  },
};
</script>

<style lang="scss" scoped>
.layout-container {
  height: 100%;
}
.sidebar {
  width: 16.5%;
}
.content-area {
  transition: margin-left 0.5s ease-in-out;
}
.inq-table {
  margin-top: 5.9rem;
  th {
    position: sticky;
    z-index: 48;
    top: 5.75rem;
  }
}

.delete-confirm {
  position: fixed;
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
}

.confirm-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9998;
}
</style>